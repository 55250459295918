import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import './UpdatedHomeFAQs.scss';
import { UpdatedFaq } from '../../FAQs/faqs-list-interface';
import FAQ from '../../FAQs/FAQ';
import useFaqExpansion from '../../../hooks/useFaqExpansion';
import { FAQS } from '../../internal-links';
import { Container } from 'react-bootstrap';

interface Props {
  faqs?: UpdatedFaq[][];
  allFaqText?: string;
  hideAllFaqsLink?: boolean;
  faqTitle?: string;
  color?: string;
}

const UpdatedHomeFAQs: React.FC<Props> = ({
  faqs,
  allFaqText,
  faqTitle,
  hideAllFaqsLink,
  color,
}) => {
  const { expandedFaq, setExpandedFaq } = useFaqExpansion();
  const [defaultFaqs, setDefaultFaqs] = useState<UpdatedFaq[][]>([[], [], [], [], []]);

  useEffect(() => {
    import('../../FAQs/faqs-list')
      .then((module) => {
        // Type assertion to match the expected structure
        const faqsList = module.faqsList as UpdatedFaq[];
        console.log('faqsList:', faqsList); // Log to check data
        const splitFaqs = [
          faqsList.slice(0, 12),
          faqsList.slice(12, 17),
          faqsList.slice(17, 20),
          faqsList.slice(20, 23),
          faqsList.slice(23, 27),
          faqsList.slice(27, 34),
        ];
        setDefaultFaqs(splitFaqs);
      })
      .catch(err => {
        console.error('Failed to load faqs-list:', err);
      });
  }, []);

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  const faqsList = faqs?.length ? faqs[activeTab] : defaultFaqs[activeTab];

  return (
    <div id="FAQ">
      <Container>
        <h2>{faqTitle || 'FAQ'}</h2>

        <div className="content-container">
          <div className="tab-navigation">
            {['Account Related', 'KYC', 'Deposit', 'Withdrawal', 'Contest and Gameplay Issues', 'TDS'].map((tab, index) => (
              <button
                key={index}
                className={`tab-button ${activeTab === index ? 'active' : ''}`}
                onClick={() => handleTabClick(index)}
              >
                {tab}
              </button>
            ))}
          </div>
          <div className="content">
            <div className="faqs">
              {faqsList && faqsList.length > 0 ? (
                faqsList.map((f, i) => (
                  <React.Fragment key={`${f.question}-${i}`}>
                    <FAQ
                      question={f.question}
                      answer={f.answer}
                      answerElement={f.answerElement}
                      expanded={expandedFaq === i}
                      setExpanded={setExpandedFaq}
                      questinNumber={i}
                      color={color}
                    />
                    {i !== faqsList.length - 1 && <div className="separator" />}
                  </React.Fragment>
                ))
              ) : (
                <p>No FAQs available</p>
              )}
            </div>

            {!hideAllFaqsLink && (
              <Link to={FAQS} className="cta">
                <div>{allFaqText || 'GO TO FAQ PAGE'}</div>
                <div className="right-icon">
                  <StaticImage
                    src="../../../images/index/common/arrow-right.png"
                    alt="more faqs"
                    className="arrow"
                    width={24}
                  />
                </div>
              </Link>
            )}

            <div className="Uchip-left">
              <StaticImage
                src="../../../images/index/common/chip-left.png"
                alt="chip left"
                className="Uchip-img"
                quality={100}
              />
            </div>
            <div className="Uchip-right">
              <StaticImage
                src="../../../images/index/common/chip-right.png"
                alt="chip right"
                className="Uchip-img"
                quality={100}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default UpdatedHomeFAQs;
