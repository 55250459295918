import React from 'react'
import ClubSvg from '../../../../images/svg/Club.inline.svg'
import LinkButton from '../../LinkButton/LinkButton'
import { pokerOneLinksHomePage } from '../../../one-links'
import { StaticImage } from 'gatsby-plugin-image'

const DesktopScreen = ({ lang = 'eng' }: { lang?: 'eng' | 'hindi' }) => {
  const title =
    lang === 'eng' ? (
      <>
        {' '}
        Claim Your Victory <br />
        on India's Lowest <br />
        POKER Rake Tables!
      </>
    ) : (
      <>
        भारत का सबसे लोकप्रिय <br /> पोकर क्लब
      </>
    )

  const subHeading = lang === 'eng' ? 'Jeeto Befikar!' : 'जीतो बेफिकर!'

  const buttonText = lang === 'eng' ? 'Play Now' : 'अभी खेलें'

  const tagLine =
    lang === 'eng' ? (
      <>
        {' '}
        Trusted choice of more than <br /> 2 crore Poker Players
      </>
    ) : (
      <>
        2 करोड़ से अधिक पोकर <br /> खिलाड़ियों की विश्वसनीय पसंद
      </>
    )

  return (
    <div className="desktop-container d-none d-lg-block">
      <div className="background-image">
        <StaticImage
          src="../../../../images/index/MainBanner/vedaa-desktop.png"
          alt="main-banner"
          style={{ width: '100%', height: '100%' }} // Ensure the image takes full width and height
          imgStyle={{ objectFit: 'cover' }}
        />
      </div>
      <div className="main-content">
        <div>
          <h1 className="desktop-heading">{title}</h1>
          {/* <p className="sub-heading">{subHeading}</p> */}
        </div>

        <div>
          <LinkButton btnFor="desk" oneLink={pokerOneLinksHomePage}>
            {buttonText}
          </LinkButton>

          <div className="tag-line">
            <div style={{ width: '15px' }}>
              <img src={ClubSvg} alt="svg" />
            </div>
            <p>{tagLine}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DesktopScreen
