import React, { useState, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import './PokerMainBanner.scss';
import LinkButton from '../LinkButton/LinkButton';
import ClubSvg from '../../../images/svg/Club.inline.svg';
import { pokerOneLinksHomePage } from '../../one-links';

const PokerMainBanner = ({ lang = 'eng' }: { lang?: 'eng' | 'hindi' }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const title =
    lang === 'eng' ? (
      <>
        {' '}
        Why To Play Poker On <br /> Mega Poker?
      </>
    ) : (
      <>
        मेगा पोकर पर पोकर <br /> क्यों खेलें?
      </>
    );

  const featuresList = lang === 'eng'
    ? ['Zero Fraud', 'Super Fast Withdrawal', 'Amazing User-Interface', 'Best Deposit Offer']
    : ['शून्य धोखाधड़ी', 'सुपर फास्ट निकासी', 'अद्भुत यूजर-इंटरफेस', 'सर्वश्रेष्ठ जमा ऑफर'];

  const featuresElements = featuresList.map((feature, index) => (
    <li key={index}>
      <span className="img-container">
        <img src={ClubSvg} alt="svg" />
      </span>
      {feature}
    </li>
  ));

  return (
    <div className="poker-desktop-container">
      <div className="main-content">
        <h1 className="desktop-heading">{title}</h1>
        <ul className="features-list">{featuresElements}</ul>
        <LinkButton btnFor="mobile" oneLink={pokerOneLinksHomePage}>
          {isMobile ? (lang === 'eng' ? 'Download' : 'डाउनलोड') : (lang === 'eng' ? 'Sign Up' : 'साइनअप')}
        </LinkButton>
      </div>
      <div className="poker-background-image">
        <div className="d-md-none">
          <StaticImage
            src="../../../images/index/MainBanner/Poker/person-mobile.png"
            alt="poker-promotion"
          />
        </div>
        <div className="d-none d-md-block">
          <StaticImage
            src="../../../images/index/MainBanner/Poker/poker-person-desktop.png"
            alt="poker-promotion"
          />
        </div>
      </div>
    </div>
  );
};

export default PokerMainBanner;
