import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import ClubSvg from '../../../../images/svg/Club.inline.svg'
import LinkButton from '../../LinkButton/LinkButton'
import { pokerOneLinksHomePage } from '../../../one-links'

const MobileScreen = ({ lang = 'eng' }: { lang?: 'eng' | 'hindi' }) => {
  const title =
    lang === 'eng' ? (
      <>
        {' '}
        INDIA'S HOTTEST <br /> POker club
      </>
    ) : (
      <>
        भारत का सबसे लोकप्रिय <br /> पोकर क्लब
      </>
    )

  const subHeading = lang === 'eng' ? 'Jeeto Befikar!' : 'जीतो बेफिकर!'

  const buttonText = lang === 'eng' ? 'Play Now' : 'अभी खेलें'

  const tagLine =
    lang === 'eng'
      ? 'Trusted choice of more than 2 crore Poker Players'
      : '2 करोड़ से अधिक पोकर खिलाड़ियों की विश्वसनीय पसंद'

  return (
    <div className="mobile-container d-lg-none">
      <div className="inner-content">
        <div className="img-container">
          <StaticImage
            src="../../../../images/index/MainBanner/vedaa-mobile.png"
            alt="main-mobile-banner"
            quality={100}
            style={{ width: '100%', height: '100%' }} // Ensure the image takes full width and height
            imgStyle={{ objectFit: 'cover' }}
          />
          {/* <div className="heading-description"> */}
          {/* <h1 className="mobile-heading">{title}</h1>
          <p className="sub-heading">{subHeading}</p> */}
          <LinkButton btnFor="mobile" oneLink={pokerOneLinksHomePage}>
            {buttonText}
          </LinkButton>
          <div className="image-bottom-line">
            <span
              style={{
                width: '15px',
              }}
            >
              <img src={ClubSvg} alt="svg" />
            </span>
            <p>{tagLine}</p>
            <span
              style={{
                width: '15px',
              }}
            >
              <img src={ClubSvg} alt="svg" />
            </span>
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  )
}

export default MobileScreen
