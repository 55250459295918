import React, { useEffect, useState } from 'react'
import './MainBanner.scss'
import { graphql, useStaticQuery } from 'gatsby'
import MobileScreen from './MobileScreen'
import DesktopScreen from './DesktopScreen'
import PokerMainBanner from '../../PokerPage/PokerMainBanner'

export enum PageNameEnum {
  homePage = 'homePage',
  pokerMain = 'pokerMain',
}

const MainBanner = ({
  lang = 'eng',
  pageName = PageNameEnum.homePage,
}: {
  lang?: 'eng' | 'hindi'
  pageName?: PageNameEnum
}) => {
  const [isMobile, setIsMobile] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      desktop: file(
        relativePath: { eq: "index/MainBanner/pattern-desktop.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const imageData = data.desktop.childImageSharp.fluid.src
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Set initial value based on current window size
      setIsMobile(window.innerWidth <= 900)

      const handleResize = () => {
        setIsMobile(window.innerWidth <= 900)
      }

      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  const homePageContent = (
    <div className="main-page-banner">
      {isMobile ? <MobileScreen lang={lang} /> : <DesktopScreen lang={lang} />}
    </div>
  )

  const getPageContent = (pageName: PageNameEnum) => {
    switch (pageName) {
      case 'homePage':
        return homePageContent

      case 'pokerMain':
        return <PokerMainBanner lang={lang} />

      default:
        return homePageContent
    }
  }

  return (
    <div
      style={{
        backgroundImage: `url(${imageData})`,
      }}
      id="banner-container"
    >
      {getPageContent(pageName)}
    </div>
  )
}

export default MainBanner
